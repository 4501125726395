import React from 'react'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

type BannerActiveProps = {
  name: string
  children: React.ReactNode
}

/**
 * Use this component to restrict part of the UI behind a banner active flag.
 *
 * @example
 * ```tsx
 * <BannerActive name="myBanner">
 *  <MyComponent />
 * </BannerActive>
 * ````
 */
const BannerActive = ({
  name,
  children,
}: BannerActiveProps): JSX.Element | null => {
  const { isActive } = useDismissableBanner(name)

  if (isActive) {
    return <>{children}</>
  }

  return null
}

export { BannerActive }
export type { BannerActiveProps }
