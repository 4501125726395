import React from 'react'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import { ProgressBar, Text } from '@bufferapp/ui'

import type {
  Checklist,
  ChecklistProgress,
} from '~publish/legacy/welcome-checklist/types'
import {
  Header,
  ProgressBarWrapper,
  ChecklistTitle,
  DismissButton,
} from './style'

const ChecklistHeader = ({
  checklist,
  checklistProgress,
  onDismissWelcomeChecklist,
}: {
  checklist: Checklist
  checklistProgress: ChecklistProgress
  onDismissWelcomeChecklist: () => void
}): JSX.Element => {
  const shouldShowProgressBar = checklistProgress.progressPercent > 0
  return (
    <Header>
      <ChecklistTitle>
        <Text type="p">{checklist.title}</Text>
        <DismissButton
          role="button"
          aria-label="Dismiss Welcome Checklist"
          onClick={onDismissWelcomeChecklist}
        >
          <CrossIcon aria-hidden="true" focusable="false" />
        </DismissButton>
      </ChecklistTitle>
      {shouldShowProgressBar && (
        <ProgressBarWrapper>
          <Text type="p">
            {checklistProgress.itemsCompleted} of {checklistProgress.itemsTotal}
          </Text>
          <ProgressBar
            className="checklistProgress"
            progress={`${checklistProgress.progressPercent}%`}
          />
        </ProgressBarWrapper>
      )}
    </Header>
  )
}

export default ChecklistHeader
