/* TODO: Remove this file when we have a Single App as this has been duplicated from
 * the AppShell
 */

export const DATES = {
  inMonthsFromNow(months: number): Date {
    const now = new Date()
    return new Date(now.setMonth(now.getMonth() + months))
  },
  inDaysFromNow(days: number): Date {
    const now = new Date()
    return new Date(now.setHours(now.getHours() + days * 24))
  },
}

export function setCookie({
  key,
  value,
  expires,
}: {
  key: string
  value: boolean
  expires: Date
}): void {
  document.cookie = `appshell_${key}=${value};domain=.buffer.com;path=/;expires=${expires.toUTCString()}`
}

export function getCookie({ key }: { key: string }): string | boolean | null {
  const regex = new RegExp(`appshell_${key}=(\\w+)`, 'g')
  const cookie = document.cookie.match(regex) || [null]
  const [, value] = cookie[0]?.split('=') || [null, false]
  return value
}
