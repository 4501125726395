/* eslint-disable @typescript-eslint/naming-convention */
import { type ApolloError, gql, useQuery } from '@apollo/client'
import { useAccount } from '~publish/legacy/accountContext'
import type { Checklist } from '~publish/legacy/welcome-checklist/types'

export const QUERY_CHECKLISTS = gql`
  query checklists($input: OrganizationIdInput!) {
    checklists(input: $input) {
      ... on ChecklistsResponse {
        checklists {
          id
          title
          items {
            id
            description
            isCompleted
          }
        }
      }
      ... on CoreWebAppCommonError {
        code
        error
      }
    }
  }
`

type useChecklistsReturn = {
  checklists: Checklist[]
  loading: boolean
  error: ApolloError | undefined
  refetch: () => void
}

export const useChecklists = (): useChecklistsReturn => {
  const { account } = useAccount()
  const organizationId = account?.currentOrganization?.id

  const { data, loading, error, refetch } = useQuery(QUERY_CHECKLISTS, {
    variables: { input: { organizationId } },
  })

  return { loading, error, checklists: data?.checklists?.checklists, refetch }
}
